<template>
  <div v-if="jdata">
    <v-card class="px-5 mb-10">
      <v-row>
        <v-col cols="12" md="11" class="my-auto">
          <h3 class="primary--text">
            {{ unor_name.toUpperCase() }}
          </h3>
          <h4 class="primary--text font-weight-light" v-if="jdata.namaJabatan">
            DETAIL JABATAN - {{ jdata.namaJabatan.toUpperCase() }}
          </h4>
        </v-col>

        <MEdit />

        <v-col cols="12" md="1" class="text-right my-auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                icon
                v-bind="attrs"
                v-on="on"
                @click="editItem()"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div v-if="jdata">
      <v-card class="pa-5" flat>
        <v-row dense>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="jdata.namaJabatan"
              label="Nama Jabatan"
              outlined
              dense
              readonly
              filled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider class="mb-4 mt-n3"></v-divider>

        <v-row dense v-if="jdata.janis_jabatan">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="jdata.janis_jabatan.nama"
              dense
              flat
              outlined
              label="Jenis Jabatan"
              readonly
              filled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field
              v-model="jdata.janis_jabatan.kelompok"
              label="Rumpun Jabatan"
              outlined
              dense
              readonly
              filled
            ></v-text-field>
          </v-col>

          <!-- ESELON -->
          <v-col
            cols="12"
            md="6"
            v-if="
              jdata.janis_jabatan.atasan == 2 ||
              jdata.janis_jabatan.id == 25 ||
              jdata.janis_jabatan.id == 7 ||
              jdata.janis_jabatan.id == 8
            "
          >
            <v-autocomplete
              v-model="jdata.eselon.id"
              :items="refeselon"
              dense
              outlined
              item-value="id"
              item-text="nama"
              label="Eselon"
              readonly
              filled
            ></v-autocomplete>
          </v-col>

          <!-- FUNGSIONAL -->
          <v-col
            cols="12"
            md="6"
            v-if="
              jdata.janis_jabatan.atasan == 13 ||
              jdata.janis_jabatan.atasan == 14 ||
              jdata.janis_jabatan.atasan == 19 ||
              jdata.janis_jabatan.atasan == 28 ||
              jdata.janis_jabatan.atasan == 29 ||
              jdata.janis_jabatan.atasan == 34
            "
          >
            <v-autocomplete
              v-model="jdata.fungsional.id"
              :items="reffungsional"
              dense
              outlined
              item-value="id"
              item-text="nama"
              label="Fungsional"
              readonly
              filled
            ></v-autocomplete>
          </v-col>

          <!-- PELAKSANA -->
          <v-col
            cols="12"
            md="6"
            v-if="
              jdata.janis_jabatan.atasan == 9 || jdata.janis_jabatan.id == 9
            "
          >
            <v-autocomplete
              v-model="jdata.pelaksana.id"
              :items="refpelaksana"
              dense
              outlined
              item-value="id"
              item-text="nama"
              label="Pelaksana"
              readonly
              filled
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="jdata.bup"
              label="Batas Usia Pensiun"
              outlined
              dense
              type="number"
              readonly
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="jdata.kelas_jabatan"
              :items="refkelasjabatan"
              label="Kelas Jabatan"
              item-value="id"
              item-text="nama"
              outlined
              dense
              readonly
              filled
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="jdata.work_place"
              :items="refworkplace"
              label="Work Place"
              item-value="id"
              item-text="nama"
              outlined
              dense
              readonly
              filled
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="jdata.kebutuhan"
              label="Kebutuhan"
              outlined
              dense
              type="number"
              readonly
              filled
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="jdata.nama_atasan"
              label="Atasan"
              outlined
              dense
              readonly
              filled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-divider class="my-5"></v-divider>

    <div>
      <CIkhtisar />
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";

import MEdit from "@/components/anjab/petajabatan/edit";
import CIkhtisar from "@/components/anjab/infojabatan/ikhtisarjab/view";

import SEdit from "@/store/anjab/petajabatan/edit";
import SView from "@/store/anjab/petajabatan/view";

export default {
  components: {
    MEdit,
    CIkhtisar,
  },

  data: () => ({
    token: Cookie.get("token"),
    unor_name: localStorage.getItem("unor"),

    refeselon: [],
    refpelaksana: [],
    reffungsional: [],
    refworkplace: [],
    refkelasjabatan: [],

    jdata: {},
  }),

  computed: {
    refresh: {
      get() {
        return SView.state.refresh;
      },
    },

    alertMassage: {
      get() {
        return SView.state.alertMassage;
      },
    },

    success: {
      get() {
        return SView.state.success;
      },
      set(value) {
        SView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return SView.state.alertBerhasil;
      },
      set(value) {
        SView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return SView.state.alertGagal;
      },
      set(value) {
        SView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  async mounted() {
    this.refeselon = await getRef.eselon(this.token);
    this.reffungsional = await getRef.fungsional(this.token);
    this.refpelaksana = await getRef.new_pelaksana(this.token);
    this.refworkplace = await getRef.workplace(this.token);
    this.refkelasjabatan = await getRef.kelasjabatan(this.token);
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "anjab/id/" + this.$route.params.id;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          SView.commit("refreshData", false);
          this.ldata = false;
          this.jdata = res.data.mapData[0];
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log("Error", error.message);
        });
    },

    editItem() {
      SEdit.commit("TModal", true);
      SEdit.commit("VData", this.jdata);
    },
  },
};
</script>
